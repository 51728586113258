<template lang="pug">
.wrapper.i-wrap
  .section-left
  .section-mid
    .container(v-if="loading" style="justify-content:center")
      Spinner
    transition(name="slideup")
      .user-favorite-wrap(v-if="!loading && type =='movie'")
        h1(style="text-align:center;") 保存的電影
        .user-favorite-list(v-if="favorites.movie.length > 0")
          .user-favorite(v-for="favorite in favorites.movie")
            mtesCard(
              :mtes="favorite"
            )
        .user-favorite-list(v-else)
          router-link(to="/genre/movie") 尋找你想要的電影
            //- Review(
            //-   :type="`movie`"
            //-   :id="favorite._id"
            //-   :review="review"
            //- )
      .user-favorite-wrap(v-if="!loading && type =='star'")
        h1(style="text-align:center;") 我最喜歡的明星
        .user-favorite-list(v-if="favorites.star.length > 0")
          .user-favorite(v-for="favorite in favorites.star")
            .user-favorite-star
              router-link(:to="`/star/${favorite._id}`")
                img(:src="favorite.thumbnail")                
                span(v-if="favorite.nameCH") 
                  h3 {{favorite.nameCH}}
                  p {{favorite.nameEN}}
                span(v-else)
                  h3 {{favorite.nameEN}} 
        .user-favorite-list(v-else)
          p 這裡沒有最喜歡的明星
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { mapActions, mapState } from "vuex";
import mtesCard from "@/components/mtesCard"
// import Review from "@/components/Review"

export default {  
  name: 'UserFavorites',
  components:{
    Spinner,
    mtesCard,  
    
  },
  props:{
    type:String,
  },
  data(){
    return{      
      loading:true,
      favorites:[],
    }
  },
  computed:{
    ...mapState(['user']),
  },
  metaInfo(){
    var theTitle = ''
    if(!this.loading){
      if(this.type == 'movie') theTitle = '保存的電影'
      if(this.type == 'star') theTitle = '我最喜歡的明星'
    }
    return {
      title: this.loading ? 'Loading...': theTitle,
      titleTemplate: "%s - 臭豆腐電影"
    }
  },
  methods:{
    async init(){
      var response = await this.axios.get(`/api/user/favorites/${this.type}`);
      this.favorites = response.data
      this.loading = false;
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped lang="scss">
  .user-favorite-list{
    display:flex;
    justify-content:center;
    flex-wrap:wrap;
  }
  .user-favorite{
    margin:10px;
    max-width:300px;
    flex-grow:0;  
  }
  .user-favorite-star{
    text-align: center;
    width:200px;
  }
</style>
